<template>
    <div class="form-group">
        <label for="rules">Conditions</label>
        <div class="d-flex justify-content-between">
            <b-form-select
                v-model="conditions.type"
                :options="conditionsTypeOptions"
                @input="$emit('input', conditions)"
            />
            <button
                type="button"
                class="col-1 btn btn-success waves-effect waves-light ml-2"
                @click="addRule"
            >
                Add
            </button>
        </div>
        <div class="row mt-1">
            <div
                v-for="(rule, index) in conditions.rules"
                :key="index"
                class="col-12 form-group d-flex justify-content-between"
            >
                <b-form-select
                    v-model="rule.type"
                    :options="availableConditions"
                    class="col-5"
                    @input="$emit('input', conditions)"
                />
                <input
                    v-model="rule.value"
                    type="number"
                    class="form-control col-5"
                    min="0"
                    step="1"
                    @input="$emit('input', conditions)"
                />
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light"
                    @click="removeRule"
                >
                    Remove
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object | String,
            required: false
        }
    },

    data() {
        const defaultConditions = {
            type: 'or',
            rules: []
        };

        return {
            conditionsTypeOptions: [
                { value: 'or', text: 'One of conditions has to be reached' },
                { value: 'and', text: 'All conditions have to be reached' }
            ],
            defaultConditions,
            conditions: { ...defaultConditions },
            availableConditions: [
                { text: 'Is equal', value: 'eq' },
                { text: 'Is not', value: 'not' },
                { text: 'Greater than', value: 'gt' },
                { text: 'Less than', value: 'lt' }
            ]
        };
    },

    watch: {
        value: {
            handler(val) {
                if (!val) {
                    this.conditions = { ...this.defaultConditions };

                    return;
                }

                if (typeof val === 'string') {
                    val = JSON.parse(val);
                }

                this.conditions = { ...val };
            },
            immediate: true
        }
    },

    methods: {
        removeRule(index) {
            this.conditions.rules.splice(index, 1);
            this.$emit('input', this.conditions);
        },

        addRule() {
            this.conditions.rules = [
                ...this.conditions.rules,
                { type: 'eq', value: 0 }
            ];
            this.$emit('input', this.conditions);
        }
    }
};
</script>
