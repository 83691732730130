<template>
    <div class="form-group">
        <label for="rules">Apply per products</label>
        <div class="d-flex justify-content-between">
            <b-form-select
                v-model="selectedProduct"
                :options="productOptions"
            />
            <button
                type="button"
                class="col-1 btn btn-success waves-effect waves-light ml-2"
                @click="addProduct"
            >
                Add
            </button>
        </div>
        <ul class="list-group mt-3">
            <li
                v-for="(product, index) in codeProducts"
                :key="index"
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                {{ product.name }}
                <a
                    class="action-icon"
                    @click.prevent="deleteProduct(product.id)"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            products: [],
            selectedProduct: {},
            codeProducts: []
        };
    },

    computed: {
        productOptions() {
            return this.products.map(product => {
                return {
                    text: product.name,
                    value: product
                };
            });
        }
    },

    watch: {
        codeProducts: {
            handler() {
                this.$emit('input', this.codeProducts);
            },
            deep: true
        }
    },

    async mounted() {
        this.codeProducts = [...this.value];

        await this.fetchProducts();
    },

    methods: {
        ...mapActions({
            getProducts: 'products/index'
        }),

        async fetchProducts() {
            try {
                const { rows } = await this.getProducts();

                this.products = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async addProduct() {
            if (!this.selectedProduct.id) {
                return
            }

            const existingProduct = this.codeProducts.find(
                product => product.id === this.selectedProduct.id
            );

            if (existingProduct) {
                return;
            }

            this.codeProducts.push(this.selectedProduct);

            this.selectedProduct = {};
        },

        deleteProduct(id) {
            this.codeProducts = [
                ...this.codeProducts.filter(
                    codeProduct => codeProduct.id !== id
                )
            ];
        }
    }
};
</script>
