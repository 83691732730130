<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="col mb-2 d-flex flex-wrap">
            <b-form-checkbox v-model="onlyArchived" switch>
                Archived Only
            </b-form-checkbox>
        </div>

        <awesome-table
            id="codes"
            :labels="labels"
            :items="codes"
            :options="{ searchInput: false, sort: true, filters: true, columnsSelector: true }"
            :pagination="pagination"
            :filters="filters"
            @add-button-click="$bvModal.show('modalCodeCreateEdit')"
            @row-click="showEditModal"
            @pagination-change="onPaginationChange"
            @filter-change="onFilterChange"
        >
            <template #[`items.limit_with_others`]="{ item }">
                <span v-if="item.limit_with_others" class="badge bg-soft-success text-success">
                    Enabled
                </span>
                <span v-else class="badge bg-soft-danger text-danger">
                    Disabled
                </span>
            </template>
            <template #[`items.allow_with_limited`]="{ item }">
                <span v-if="item.allow_with_limited" class="badge bg-soft-success text-success">
                    Enabled
                </span>
                <span v-else class="badge bg-soft-danger text-danger">
                    Disabled
                </span>
            </template>
            <template #[`items.auto_applied`]="{ item }">
                <span v-if="item.auto_applied" class="badge bg-soft-success text-success">
                    Enabled
                </span>
                <span v-else class="badge bg-soft-danger text-danger">
                    Disabled
                </span>
            </template>
            <template #[`items.mobile_app_flow`]="{ item }">
                <span v-if="item.mobile_app_flow" class="badge bg-soft-success text-success">
                    Enabled
                </span>
                <span v-else class="badge bg-soft-danger text-danger">
                    Disabled
                </span>
            </template>
            <template #[`items.discount`]="{ item, value }">
                <span v-if="item.type === 'fixed' || item.type === 'locked'">
                    ${{ value | variantFixedPrice }}
                </span>
                <span v-else-if="item.type === 'percentage'">
                    {{ value }}%
                </span>
                <span v-else>
                    {{ value }}
                </span>
            </template>
            <template #[`items.actions`]="{ item, index }">
                <a
                    href="#"
                    class="action-icon"
                    @click.prevent.stop="
                        $router.push(`/promo-codes/${item.id}/orders`)
                    "
                >
                    <i class="fas fa-file-invoice-dollar" />
                </a>
                <a
                    v-if="!onlyArchived"
                    href="#"
                    class="action-icon"
                    @click.prevent.stop="onDeleteCode(item, index)"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </template>
        </awesome-table>

        <modal-code-create-edit
            :modal-title="codeToUpdate ? 'Edit Code' : 'Add New Code'"
            :button-text="codeToUpdate ? 'Update' : 'Save'"
            :updating-code="!!codeToUpdate"
            :code="codeToUpdate"
            @createCode="createCodeHandler"
            @updateCode="updateCodeHandler"
            @hidden="hiddenModalCodeCreteEditHandler"
        />
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import ModalCodeCreateEdit from '@components/modals/code/modalCodeCreateEdit';

export default {
    page: {
        title: 'Promo Codes',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        ModalCodeCreateEdit,
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Promo Codes',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Promo Codes',
                    active: true
                }
            ],
            codeToUpdate: null,
            codes: [],
            onlyArchived: false,
            labels: [
                { text: 'Code name', value: 'name' },
                {
                    text: 'Discount Type',
                    value: 'type',
                    type: 'enum',
                    enumOptions: ['fixed', 'percentage', 'quantity', 'locked']
                },
                {
                    text: 'Times used',
                    value: 'used',
                    type: 'number',
                    filterable: false,
                    sortable: false
                },
                { value: 'limit', type: 'number' },
                { value: 'limit_with_others', type: 'boolean' },
                { value: 'allow_with_limited', type: 'boolean' },
                { value: 'auto_applied', type: 'boolean' },
                { value: 'mobile_app_flow', type: 'boolean' },
                { value: 'discount', filterable: false, sortable: false },
                { value: 'start_date', filter: 'dateOnly', type: 'date' },
                { value: 'end_date', filter: 'dateOnly', type: 'date' },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                { value: 'actions', sortable: false, filterable: false }
            ],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
        };
    },

    watch: {
        onlyArchived() {
            this.pagination.currentPage = 1;
            this.fetchCodes();
        }
    },

    mounted() {
        this.fetchCodes();
    },

    methods: {
        ...mapActions({
            getCodes: 'codes/index',
            deleteCode: 'codes/delete'
        }),

        async fetchCodes() {
            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters,
                    onlyArchived: this.onlyArchived
                };

                const data = await this.getCodes(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.codes = data.rows;
                this.pagination.pages = Math.ceil(
                    data.count / this.pagination.perPage
                );
                this.pagination.total = data.count;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        showEditModal(code) {
            this.codeToUpdate = code;
            this.$bvModal.show('modalCodeCreateEdit');
        },

        async onDeleteCode(code, index) {
            const confirmed = await this.$confirmationModal(
                `Are you sure you want to delete this code - ${this.code.name}?`
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteCode(code);

                this.codes.splice(index, 1);
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async createCodeHandler(response) {
            if (this.pagination.currentPage !== 1) {
                this.pagination.currentPage = 1;
                await this.fetchCodes();

                return;
            }

            this.codes = [response, ...this.codes];
        },

        async updateCodeHandler(response) {
            const updatedIndex = this.codes.findIndex(
                code => code.id === response.id
            );

            this.codes[updatedIndex] = { ...response };
        },

        hiddenModalCodeCreteEditHandler() {
            this.codeToUpdate = null;
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchCodes();
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchCodes();
        }
    }
};
</script>
