<template>
    <b-modal
        id="modalCodeCreateEdit"
        centered
        class="modal-demo"
        header-bg-variant="dark"
        header-text-variant="light"
        size="lg"
        title-class="text-light"
        title-tag="h4"
        @hidden="$emit('hidden')"
        @show="openModalHandler"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left">
            <form @submit.stop.prevent="submitCodeDataHandler">
                <div class="form-group">
                    <label for="category">Code</label>
                    <div class="input-group">
                        <input
                            v-model="data.name"
                            class="form-control"
                            :class="{ 'is-invalid': $v.data.name.$error }"
                            placeholder="Promo code name"
                            type="text"
                        />
                        <span class="input-group-btn">
                            <a
                                class="btn btn-info waves-effect waves-light ml-1"
                                @click="generateCode"
                            >
                                Generate
                            </a>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="category">Discount Type</label>
                    <div>
                        <div class="form-group">
                            <b-form-select
                                v-model="data.type"
                                :class="{ 'is-invalid': $v.data.type.$error }"
                                :options="options"
                            />
                        </div>
                        <div v-if="data.type === 'quantity'" class="form-group">
                            <template v-for="(block, blockId) in children">
                                <div :key="block.id" class="form-group">
                                    <div class="row">
                                        <div class="col">
                                            <input
                                                v-model="
                                                    children[blockId].quantity
                                                "
                                                class="form-control"
                                                :class="{
                                                    'is-invalid':
                                                        $v.children.$each.$iter[
                                                            blockId
                                                        ].quantity.$error
                                                }"
                                                placeholder="Quantity"
                                                type="number"
                                                @blur="
                                                    $v.children.$each.$iter[
                                                        blockId
                                                    ].quantity.$touch;
                                                "
                                                @input="
                                                    $v.children.$each.$iter[
                                                        blockId
                                                    ].quantity.$touch;
                                                "
                                            />
                                        </div>
                                        <div class="col">
                                            <b-form-select
                                                v-model="children[blockId].type"
                                                :class="{
                                                    'is-invalid':
                                                        $v.data.type.$error
                                                }"
                                                :options="optionsForQuantity"
                                            ></b-form-select>
                                        </div>
                                        <div class="col">
                                            <currency-input
                                                v-if="
                                                    children[blockId].type ===
                                                        'fixed'
                                                "
                                                v-model="
                                                    children[blockId].discount
                                                "
                                                class="form-control"
                                                placeholder="Discount value"
                                                type="text"
                                            />
                                            <input
                                                v-else
                                                v-model="
                                                    children[blockId].discount
                                                "
                                                class="form-control"
                                                :class="{
                                                    'is-invalid':
                                                        $v.children.$each.$iter[
                                                            blockId
                                                        ].discount.$error
                                                }"
                                                placeholder="Discount value"
                                                type="number"
                                                @blur="
                                                    $v.children.$each.$iter[
                                                        blockId
                                                    ].discount.$touch;
                                                "
                                                @input="
                                                    $v.children.$each.$iter[
                                                        blockId
                                                    ].discount.$touch;
                                                "
                                            />
                                        </div>
                                        <div class="col">
                                            <a
                                                class="btn btn-danger"
                                                style="color: white;"
                                                @click="removeBlock(blockId)"
                                            >
                                                -
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <a
                                class="btn btn-info"
                                style="color: white;"
                                @click="addBlock"
                            >
                                +
                            </a>
                        </div>
                        <div
                            v-else-if="
                                ['percentage', 'fixed', 'locked'].includes(
                                    data.type
                                )
                            "
                            class="form-group"
                        >
                            <currency-input
                                v-if="
                                    data.type === 'fixed' ||
                                        data.type === 'locked'
                                "
                                v-model="data.discount"
                                class="form-control"
                                :class="{
                                    'is-invalid': $v.data.discount.$error
                                }"
                                :placeholder="
                                    `${
                                        data.type === 'fixed'
                                            ? 'Discount'
                                            : 'Price'
                                    } value`
                                "
                                type="text"
                            />
                            <input
                                v-else
                                v-model="data.discount"
                                class="form-control"
                                :class="{
                                    'is-invalid': $v.data.discount.$error
                                }"
                                :error-messages="discountErrors"
                                placeholder="Discount value"
                                type="number"
                                @blur="$v.data.discount.$touch"
                                @input="$v.data.discount.$touch;"
                            />
                            <div
                                v-if="discountErrors.length > 0"
                                class="invalid-feedback"
                            >
                                {{ discountErrors[0] }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <b-form-checkbox
                        id="order_notifications"
                        v-model="checkedPerUserUsage"
                        class="checkbox-circle"
                        name="order_notifications"
                    >
                        Limit Per Customer
                    </b-form-checkbox>
                    <div class="input-group">
                        <input
                            v-show="checkedPerUserUsage"
                            v-model="data.limit_per_customer"
                            class="form-control"
                            placeholder="Number of times this discount can be used by a single customer"
                            type="text"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <b-form-checkbox
                        id="total_limit_usage"
                        v-model="checkedTotalUsage"
                        class="checkbox-circle"
                        name="total_limit_usage"
                    >
                        Total Usage Limit
                    </b-form-checkbox>
                    <div class="input-group">
                        <input
                            v-show="checkedTotalUsage"
                            v-model="data.limit"
                            class="form-control"
                            placeholder="Number of times this discount can be used in total"
                            type="text"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <b-form-checkbox v-model="data.mobile_app_flow" switch>
                        Mobile App Flow
                    </b-form-checkbox>
                </div>
                <div class="form-group">
                    <b-form-checkbox
                        v-model="data.limit_with_others"
                        :disabled="data.allow_with_limited"
                        switch
                    >
                        Limit usage with others codes
                    </b-form-checkbox>
                </div>
                <div class="form-group">
                    <b-form-checkbox
                        v-model="data.allow_with_limited"
                        :disabled="data.limit_with_others"
                        switch
                    >
                        Allow usage with limited codes
                    </b-form-checkbox>
                </div>
                <div v-if="data.allow_with_limited" class="form-group">
                    <input
                        v-model="data.allow_with_limited_limit"
                        class="form-control"
                        :class="{
                            'is-invalid':
                                $v.data.allow_with_limited_limit.$error
                        }"
                        placeholder="Allowed codes limit"
                        type="number"
                        @blur="$v.data.allow_with_limited_limit.$touch"
                        @input="$v.data.allow_with_limited_limit.$touch;"
                    />
                </div>
                <div v-if="data.type !== 'quantity'" class="form-group">
                    <b-form-checkbox v-model="data.apply_per_orthotic" switch>
                        Apply per orthotic
                    </b-form-checkbox>
                </div>
                <div v-if="data.type !== 'quantity'" class="form-group">
                    <b-form-checkbox
                        v-model="data.apply_per_other_product"
                        switch
                    >
                        Apply per other product
                    </b-form-checkbox>
                </div>
                <div v-if="data.type !== 'quantity'" class="form-group">
                    <b-form-checkbox v-model="data.apply_per_bundle" switch>
                        Apply per bundle
                    </b-form-checkbox>
                </div>
                <div class="form-group">
                    <b-form-checkbox
                        v-model="data.customer_segmentation_enabled"
                        switch
                    >
                        Customer segmentation enabled
                    </b-form-checkbox>
                </div>
                <div
                    v-if="data.customer_segmentation_enabled"
                    class="form-group ml-2 mt-n1"
                >
                    <b-form-checkbox
                        id="apply_for_new_visitor"
                        v-model="data.apply_for_new_visitor"
                        class="checkbox-circle"
                        name="apply_for_new_visitor"
                    >
                        New Visitor
                    </b-form-checkbox>
                    <b-form-checkbox
                        id="apply_for_returning_visitor"
                        v-model="data.apply_for_returning_visitor"
                        class="checkbox-circle"
                        name="apply_for_returning_visitor"
                    >
                        Returning Visitor
                    </b-form-checkbox>
                    <b-form-checkbox
                        id="apply_for_logged_in_customer"
                        v-model="data.apply_for_logged_in_customer"
                        class="checkbox-circle"
                        name="apply_for_logged_in_customer"
                    >
                        Returning Customer
                    </b-form-checkbox>
                </div>
                <div class="form-group">
                    <b-form-checkbox
                        v-model="data.auto_applied"
                        switch
                    >
                        Automatically applied code
                    </b-form-checkbox>
                </div>
                <div
                    v-if="data.auto_applied"
                    class="form-group ml-2 mt-n1"
                >
                    <b-form-checkbox
                        id="show_name"
                        v-model="data.show_name"
                        class="checkbox-circle"
                        name="show_name"
                    >
                        Show code name
                    </b-form-checkbox>
                    <b-form-checkbox
                        id="show_in_product_price"
                        v-model="data.show_in_product_price"
                        class="checkbox-circle"
                        name="show_in_product_price"
                    >
                        Show in product price
                    </b-form-checkbox>
                    <b-form-checkbox
                        id="apply_after_klaviyo_submit"
                        v-model="data.apply_after_klaviyo_submit"
                        class="checkbox-circle"
                        name="apply_after_klaviyo_submit"
                    >
                        Apply after Klaviyo form submit
                    </b-form-checkbox>
                </div>
                <div class="form-group">
                    <b-form-checkbox
                        v-model="data.apply_for_abandoned_cart"
                        switch
                    >
                        Apply for abandoned cart
                    </b-form-checkbox>
                </div>
                <div v-if="data.auto_applied" class="form-group">
                    <label for="category">Order minimal value</label>
                    <currency-input
                        v-model="data.min_value"
                        class="form-control"
                        placeholder="Order minimal value"
                        type="text"
                    />
                </div>
                <div class="form-group">
                    <label for="category">Start Date</label>
                    <div class="input-group">
                        <date-picker
                            v-model="data.start_date"
                            value-type="YYYY-MM-DD"
                            :format="'DD.MM.YYYY'"
                            :lang="{
                                formatLocale: {
                                    firstDayOfWeek: 1
                                }
                            }"
                            placeholder="Select date"
                            class="form-control"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label for="category">End Date</label>
                    <div class="input-group">
                        <date-picker
                            v-model="data.end_date"
                            value-type="YYYY-MM-DD"
                            :format="'DD.MM.YYYY'"
                            :lang="{
                                formatLocale: {
                                    firstDayOfWeek: 1
                                }
                            }"
                            placeholder="Select date"
                            class="form-control"
                        />
                    </div>
                </div>
                <code-conditions
                    v-if="data.type !== 'quantity'"
                    v-model="data.conditions"
                />
                <code-products
                    v-if="
                        (data.products && data.type === 'fixed' && data.apply_per_orthotic) ||
                            (data.products && data.type === 'quantity')
                    "
                    :key="data.id"
                    v-model="data.products"
                />
            </form>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    class="btn btn-danger waves-effect waves-light mr-2"
                    type="button"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    class="btn btn-success waves-effect waves-light"
                    type="submit"
                    @click="submitCodeDataHandler"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import CurrencyInput from '@components/elements/CurrencyInput';
import CodeConditions from '@components/modals/code/CodeConditions';
import CodeProducts from '@components/modals/code/CodeProducts';

export default {
    components: {
        DatePicker,
        CurrencyInput,
        CodeConditions,
        CodeProducts
    },

    props: {
        updatingCode: {
            type: Boolean,
            required: false,
            default: true
        },
        modalTitle: {
            type: String,
            required: false,
            default: 'Add New Code'
        },
        code: {
            type: Object,
            required: false,
            default: null
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Save'
        }
    },

    data() {
        return {
            options: [
                { value: 'percentage', text: 'percentage' },
                { value: 'fixed', text: 'fixed' },
                { value: 'quantity', text: 'quantity' },
                { value: 'locked', text: 'locked price' }
            ],
            optionsForQuantity: [
                { value: 'percentage', text: 'percentage' },
                { value: 'fixed', text: 'fixed' }
            ],
            blocks: [],
            checkedPerUserUsage: false,
            checkedTotalUsage: false,
            data: {},
            children: []
        };
    },

    computed: {
        discountErrors() {
            const errors = [];

            if (!this.$v.data.discount.$dirty) return errors;

            !this.$v.data.discount.percentageFormat &&
                errors.push('Percentage discount can not be greater than 100!');

            return errors;
        }
    },

    validations: {
        children: {
            $each: {
                quantity: {
                    required
                },
                discount: {
                    requiredCondition: (value, child) => {
                        if (child.type === 'quantity') {
                            return true;
                        }

                        return !!value;
                    },

                    percentageFormat: (value, child) => {
                        if (child.type === 'percentage') {
                            return value <= 100;
                        }

                        return true;
                    }
                }
            }
        },

        data: {
            name: {
                required
            },
            type: {
                required
            },
            discount: {
                requiredCondition: function(val) {
                    if (this.data.type === 'quantity') {
                        return true;
                    }

                    if (this.data.type === 'locked') {
                        return val >= 0;
                    }

                    return !!val;
                },

                percentageFormat: function(val) {
                    if (val && this.data.type === 'percentage') {
                        return this.data.discount <= 100 && !!~this.data.type;
                    }

                    return true;
                }
            },
            allow_with_limited_limit: {
                requiredCondition: function(val) {
                    if (this.data.allow_with_limited) {
                        return !!val;
                    }

                    return true;
                },
                minValue: minValue(1)
            }
        }
    },

    methods: {
        ...mapActions({
            createCode: 'codes/store',
            updateCode: 'codes/update'
        }),

        addBlock() {
            this.children.push({
                quantity: null,
                type: 'percentage',
                discount: null
            });
        },

        removeBlock(blockId) {
            this.children.splice(blockId, 1);
        },

        hideModal() {
            this.$bvModal.hide('modalCodeCreateEdit');
        },

        async openModalHandler() {
            this.$v.$reset();
            await this.$nextTick();
            this.children = [];

            if (this.code && this.updatingCode) {
                this.checkedPerUserUsage = !!this.code.limit_per_customer;
                this.checkedTotalUsage = !!this.code.limit;

                this.data = { ...this.code };

                this.data.limit_with_others = !!this.data.limit_with_others;
                this.data.allow_with_limited = !!this.data.allow_with_limited;
                this.data.auto_applied = !!this.data.auto_applied;
                this.data.mobile_app_flow = !!this.data.mobile_app_flow;
                this.data.apply_per_orthotic = !!this.data.apply_per_orthotic;
                this.data.apply_per_other_product = !!this.data
                    .apply_per_other_product;
                this.data.show_name = !!this.data.show_name;
                this.data.show_in_product_price = !!this.data
                    .show_in_product_price;

                if (this.data.type === 'fixed' || this.data.type === 'locked') {
                    this.data.discount /= 100;
                }

                if (this.data.auto_applied) {
                    this.data.min_value /= 100;
                }

                if (this.data.type === 'quantity') {
                    this.data.children.forEach(child => {
                        let childToEdit = { ...child };

                        if (childToEdit.type === 'fixed') {
                            childToEdit.discount /= 100;
                        }

                        if (childToEdit.deleted_at === null) {
                            this.children.push(childToEdit);
                        }
                    });

                    this.children = this.children.sort(
                        (a, b) => a.quantity - b.quantity
                    );
                }

                return;
            }

            this.data = {
                type: 'percentage',
                limit_with_others: true,
                auto_applied: false,
                products: []
            };
            this.checkedPerUserUsage = false;
            this.checkedTotalUsage = false;
        },

        async submitCodeDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            !this.checkedPerUserUsage && (this.data.limit_per_customer = null);
            !this.checkedTotalUsage && (this.data.limit = null);

            if (!this.data.auto_applied) {
                this.data.min_value = null;
            } else {
                this.data.min_value *= 100;
            }

            let codeRequest = { ...this.data };

            if (codeRequest.type !== 'quantity') {
                this.children = [];
            } else {
                codeRequest.discount = null;
            }

            if (codeRequest.type === 'fixed' || codeRequest.type === 'locked') {
                codeRequest.discount *= 100;
            }

            if (this.children) {
                codeRequest.children = [
                    ...this.children.map(item => ({
                        ...item,
                        discount: item.discount * 100
                    }))
                ];
            }

            try {
                if (this.updatingCode) {
                    const response = await this.updateCode(codeRequest);
                    this.$emit('updateCode', response);
                    this.$emit('added');
                } else {
                    const response = await this.createCode(codeRequest);
                    // @TODO
                    this.$emit('createCode', response);
                    this.$emit('added');
                }
            } catch (err) {
                console.error(err);

                let message = null;

                if (
                    err.response.status === 422 &&
                    err.response.data.errors.name
                ) {
                    message = err.response.data.errors.name[0];
                }

                this.$toasterError(message);

                return;
            }

            this.$toaster(
                `Code has been ${this.updatingCode ? 'updated!' : 'added!'}`
            );

            this.$v.$reset();
            this.hideModal();
        },

        generateCode() {
            this.data.name = Math.random()
                .toString(36)
                .substring(2, 8)
                .toUpperCase();

            this.$forceUpdate();
        }
    }
};
</script>
